body {
    background-color: #e6f3f9;
}

::-webkit-scrollbar {
  width: 6px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6f3f9; 
	margin-top: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #628395; 
	border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}