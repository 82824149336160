.container {
	width: 100%;
	padding-top: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loginForm {
	display: flex;
	flex-direction: column;
}

.title {
	margin-bottom: 50px;
	text-align: center;
}

.input {
	padding: 5px;
	margin-bottom: 5px;
	font-size: 16px;
	border: none;
	border-bottom: 1px solid grey;
	color: black;
	background-color: transparent;
}

.input.error{
	border-bottom: 1px solid red;
	color: red;
}

.button {
	margin-top: 10px;
	padding: 7px 10px;
	border: none;
	border-radius: 5px;
	background-color: #628395;
	color: white;
	font-weight: 600;
	cursor: pointer;
	height: 40px;
	width: 100%;
	align-self: center;
}

.button:disabled {
  background-color: lightgray;
}